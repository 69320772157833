import React, { useState } from "react";
import './Modal.css'

const Modal = ({ isOpen, onSubmit, onClose }) => {
    const [phoneNumber, setPhoneNumber] = useState("+91 987654321");
    const [paymentMethod, setPaymentMethod] = useState("COD"); // default to COD
    const [shippingAddress, setShippingAddress] = useState("CashKaro Gurgaon Office, P - 35, 2nd Floor, UM House, Sector 44 Rd, Gurugram, Haryana 122002");

    if (!isOpen) return null;

    const handleSubmit = () => {
        onSubmit({ phoneNumber, paymentMethod, shippingAddress });
    };

    return (
       <div className='modal-overlay'>
         <div className="modal">
            {/* Modal content */}
            <input type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} placeholder="Phone Number" />

            {/* Payment Method Selection */}
            <div>
                <label>
                    <input 
                        type="radio" 
                        value="COD" 
                        checked={paymentMethod === "COD"} 
                        onChange={(e) => setPaymentMethod(e.target.value)} 
                    />
                    Cash on Delivery (COD)
                </label>
                <label>
                    <input 
                        type="radio" 
                        value="Card" 
                        checked={paymentMethod === "Card"} 
                        onChange={(e) => setPaymentMethod(e.target.value)} 
                    />
                    Card
                </label>
                <label>
                    <input 
                        type="radio" 
                        value="NetBanking" 
                        checked={paymentMethod === "NetBanking"} 
                        onChange={(e) => setPaymentMethod(e.target.value)} 
                    />
                    Net Banking
                </label>
            </div>

            <textarea value={shippingAddress} onChange={(e) => setShippingAddress(e.target.value)} placeholder="Shipping Address"></textarea>
            <button style={{backgroundColor:'#000'}} onClick={handleSubmit}>Submit</button>
            <button style={{backgroundColor:'#000'}} onClick={onClose}>Close</button>
        </div>
       </div>
    );
};

export default Modal;
