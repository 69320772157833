import React from "react";
import { BsSuitHeartFill } from "react-icons/bs";
import { GiReturnArrow } from "react-icons/gi";
import { FaShoppingCart } from "react-icons/fa";
import { MdOutlineLabelImportant } from "react-icons/md";
import Image from "../../designLayouts/Image";
import Badge from "./Badge";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../redux/orebiSlice";
import ReactGA from 'react-ga4'

const Product = (props) => {
  const dispatch = useDispatch();
  const _id = props.productName;
  const idString = (_id) => {
    return String(_id).toLowerCase().split(" ").join("");
  };
  const rootId = idString(_id);

  const navigate = useNavigate();
  const productItem = props;
  const handleProductDetails =async () => {
  //  await window.gtag("event", "view_item", {
  //     currency: "INR",
  //     value: props.price,
  //     utm_medium: localStorage.getItem("utm_medium"),
  //     items: [
  //       {
  //         item_id: props._id,
  //         item_name: props.productName,
  //         affiliation: "CashKaro",
  //         coupon: "CK20",
  //         discount: 0,
  //         index: 0,
  //         item_brand: "Google",
  //         item_category: "Apparel",
  //         item_category2: "Adult",
  //         item_category3: "Shirts",
  //         item_category4: "Crew",
  //         item_category5: "Short sleeve",
  //         item_list_id: "related_products",
  //         item_list_name: "Related Products",
  //         item_variant:props.color,
  //         location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  //         price: props.price,
  //         quantity: 1
  //       }
  //     ]
  //   });
    navigate(`/product/${rootId}`, {
      state: {
        item: productItem,
      },
    });
  };
  return (
    <div className="w-full relative group">
      <div className="max-w-80 max-h-80 relative overflow-y-hidden ">
        <div>
          <Image className="w-full h-full" imgSrc={props.img} />
        </div>
        <div className="absolute top-6 left-8">
          {props.badge && <Badge text="New" />}
        </div>
        <div className="w-full h-16 absolute bg-white -bottom-[130px] group-hover:bottom-0 duration-700">
          <ul className="w-full h-full flex flex-col items-end justify-center gap-2 font-titleFont px-2 border-l border-r">
            <li
              onClick={handleProductDetails}
              className="text-[#767676] hover:text-primeColor text-sm font-normal border-b-[1px] border-b-gray-200 hover:border-b-primeColor flex items-center justify-end gap-2 hover:cursor-pointer pb-1 duration-300 w-full"
            >
              View Details
              <span className="text-lg">
                <MdOutlineLabelImportant />
              </span>
            </li>
            <li className="text-[#767676] hover:text-primeColor text-sm font-normal border-b-[1px] border-b-gray-200 hover:border-b-primeColor flex items-center justify-end gap-2 hover:cursor-pointer pb-1 duration-300 w-full">
              Add to Wish List
              <span>
                <BsSuitHeartFill />
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="max-w-80 py-6 flex flex-col gap-1 border-[1px] border-t-0 px-4">
        <div className="flex items-center justify-between font-titleFont">
          <h2 id='productname' className="text-lg text-primeColor font-bold">
            {props.productName}
          </h2>
          <p className="text-[#767676] text-[14px]">₹{props.price}</p>
        </div>
        <div>
          <p className="text-[#767676] text-[14px]">{props.color}</p>
        </div>
        <div>
               
          <button onClick={() =>{
               dispatch(
                addToCart({
                  _id: props._id,
                  name: props.productName,
                  quantity: 1,
                  image: props.img,
                  badge: props.badge,
                  price: props.price,
                  colors: props.color,
                })
              )
              // window.gtag("event", "add_to_cart", {
              //   currency: "INR",
              //   value: props.price,
              //   utm_medium: localStorage.getItem("utm_medium"),
              //   items: [
              //     {
              //       item_id: props._id,
              //       item_name: props.productName,
              //       affiliation: "CashKaro",
              //       coupon: "CK20",
              //       discount: 20,
              //       index: 0,
              //       item_brand: "Google",
              //       item_category: "Apparel",
              //       item_category2: "Adult",
              //       item_category3: "Shirts",
              //       item_category4: "Crew",
              //       item_category5: "Short sleeve",
              //       item_list_id: "related_products",
              //       item_list_name: "Related Products",
              //       item_variant: props.color,
              //       location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
              //       price:props.price,
              //       quantity: 1
              //     }
              //   ]
              // });
          }
             
                
              } className='bg-primeColor rounded-md cursor-pointer hover:bg-black active:bg-gray-900 px-8 py-2 font-titleFont font-semibold text-lg text-gray-200 hover:text-white duration-300' style={{display:'flex',alignItems:'center',gap:'10px',padding:'10px 20px'}}> <FaShoppingCart /> Add to cart </button>
        </div>
      </div>
    </div>
  );
};

export default Product;
