import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
    createRoutesFromElements,
    Route,
    ScrollRestoration,
} from "react-router-dom";
import Footer from "./components/home/Footer/Footer";
// import FooterBottom from "./components/home/Footer/FooterBottom";
import Header from "./components/home/Header/Header";
import HeaderBottom from "./components/home/Header/HeaderBottom";
import SpecialCase from "./components/SpecialCase/SpecialCase";
import About from "./pages/About/About";
import SignIn from "./pages/Account/SignIn";
import SignUp from "./pages/Account/SignUp";
import Cart from "./pages/Cart/Cart";
import Contact from "./pages/Contact/Contact";
import Home from "./pages/Home/Home";
import Journal from "./pages/Journal/Journal";
import Offer from "./pages/Offer/Offer";
import Payment from "./pages/payment/Payment";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import Shop from "./pages/Shop/Shop";
// import { useEffect } from "react";
// import ReactGA from 'react-ga4';
// import GTM from 'react-gtm-module';

const Layout = () => {

    let ckSurvivalMinutes = 30

    function getCKSearchParams() {
        var searchParams = new URLSearchParams(window.location.search);
        var params = {};
        // Iterate through the query parameters and store them in the 'params' object
        searchParams.forEach(function (value, key) {
            params[key] = value;
        });
        return params;
    }

    function redirect(addition_parameter, length) {
        var searchParams = new URLSearchParams(window.location.search);
        let val = generateRandomString(length)
        if(addition_parameter === 'gclid'){
            searchParams.set('gad_source', generateRandomString(12));
        }
        searchParams.set(addition_parameter, val);
        const baseUrl = window.location.origin;
        const fullUrl = baseUrl + "?" + searchParams.toString();
        window.location.href = fullUrl;
    }

    function generateRandomString(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }

    function checkForAdParameters(params) {
        if (params?.utm_source?.toLowerCase().includes('googleads') && !('gclid' in params)) {
            // add gclid
            redirect("gclid", 10)
        }else if (params?.utm_source?.toLowerCase().includes('facebook') && !('fbclid' in params)) {
            // add fbclid
            redirect("fbclid", 15)
        }else if (params?.utm_source?.toLowerCase().includes('instagram') && !('igshid' in params)) {
            // add igshid
            redirect("igshid", 12)
        }else if (params?.utm_source?.toLowerCase().includes('microsoftads') && !('msclkid' in params)) {
            // add msclkid
            redirect("msclkid", 14)
        }
        //gad_souce is pending
    }

    // Function to store query parameters in local storage
    function storeCKInLocalStorage(params) {
        // Remove specific items from local storage if they are not present in 'params'
        if (!('gclid' in params)) {
            localStorage.removeItem('ck_gclid');
        }
        if (!('fbclid' in params)) {
            localStorage.removeItem('ck_fbclid');
        }
        if (!('igshid' in params)) {
            localStorage.removeItem('ck_igshid');
        }
        if (!('gad_source' in params)) {
            localStorage.removeItem('ck_gad_source')
        }
        if (!('msclkid' in params)) {
            localStorage.removeItem('ck_msclkid')
        }
        // Store each query parameter in local storage with a 'ck_' prefix
        for (var key in params) {
            if (params.hasOwnProperty(key)) {
                localStorage.setItem("ck_" + key, params[key]);
            }
        }
        // Store the current timestamp in local storage
        var currentTimestamp = Math.floor(Date.now() / 1000); // Convert milliseconds to seconds
        localStorage.setItem('ck_timestamp', currentTimestamp);
    }

    // Function to store query parameters in cookies
    function storeCKInCookies(params) {
        // Remove specific cookies if they are not present in 'params'
        if (!('gclid' in params)) {
            document.cookie = "ck_gclid=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/";
        }
        if (!('fbclid' in params)) {
            document.cookie = "ck_fbclid=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/";
        }
        if (!('igshid' in params)) {
            document.cookie = "ck_igshid=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/";
        }
        if (!('gad_source' in params)) {
            document.cookie = "ck_gad_source=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/";
        }
        if (!('msclkid' in params)) {
            document.cookie = "ck_msclkid=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/";
        }

        // Store each query parameter as a cookie with a 'ck_' prefix
        var expirationDate = new Date();
        expirationDate.setTime(expirationDate.getTime() + ckSurvivalMinutes * 60 * 1000); // Convert minutes to milliseconds
        for (var key in params) {
            if (params.hasOwnProperty(key)) {
                document.cookie = "ck_" + key + "=" + params[key] + "; expires=" + expirationDate.toUTCString() + "; path=/";
            }
        }
    }

    // Get the query parameters from the URL
    var ckSearchParams = getCKSearchParams();

    // check for ads and add other parameters
    checkForAdParameters(ckSearchParams)
    // Store the query parameters in local storage
    storeCKInLocalStorage(ckSearchParams);
    // Store the query parameters in cookies
    storeCKInCookies(ckSearchParams);
    return (
        <div>
            <Header />
            <HeaderBottom />
            <SpecialCase />
            <ScrollRestoration />
            <Outlet />
            <Footer />
            {/* <FooterBottom /> */}
        </div>
    );
};

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route>
            <Route path="/" element={<Layout />}>
                <Route index element={<Home />}></Route>
                <Route path="/shop" element={<Shop />}></Route>
                <Route path="/about" element={<About />}></Route>
                <Route path="/contact" element={<Contact />}></Route>
                <Route path="/journal" element={<Journal />}></Route>
                <Route path="/offer" element={<Offer />}></Route>
                <Route path="/product/:_id" element={<ProductDetails />}></Route>
                <Route path="/cart" element={<Cart />}></Route>
                <Route path="/success" element={<Payment />}></Route>
            </Route>
            <Route path="/signup" element={<SignUp />}></Route>
            <Route path="/signin" element={<SignIn />}></Route>
        </Route>
    )
);

function App() {
    // useEffect(() => {
    //     const tagManagerArgs = {
    //         gtmId: 'G-PJ1T347PEH'
    //     };
    //     GTM.initialize(tagManagerArgs);
    // }, []);

    return (
        <div className="font-bodyFont">
            <RouterProvider router={router} />
        </div>
    );
}

export default App;
