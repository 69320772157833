import React, { useState, useEffect } from 'react'

const Modal = ({ showModal, setShowModal }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [listItems, setListItems] = useState([]);

    useEffect(() => {
        // Simulated data for your <ul> elements
        const data = ['GoogleAds', 'Facebook', 'Instagram', 'MicrosoftAds', 'Affiliate1', 'Affiliate2', 'Affiliate3', 'CashKaro', 'EarnKaro'];
        
        const timer = setTimeout(() => {
          if (currentIndex < data.length) {
            setListItems(prevListItems => [...prevListItems, data[currentIndex]]);
            setCurrentIndex(currentIndex + 1);
          }
        }, 500); // Adjust the delay (in milliseconds) as needed
    
        return () => clearTimeout(timer);
      }, [currentIndex]);

    return (
        <div>
            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative m-6 mx-auto max-w-3xl w-full px-4 sm:px-6 md:w-auto">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        Pixel Info
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <p className="my-4 text-blueGray-500 text-lg leading-relaxed font-bold">Order Attributed to following source</p>
                                    {listItems && listItems.map((element, index) => (
                                        <ul key={index} className="pl-6" style={{ listStyle: 'disc' }}>
                                            <li>
                                                <p className="my-4 text-blueGray-500 text-lg leading-relaxed">{element}</p>
                                            </li>
                                        </ul>
                                    ))}
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Close
                                    </button>
                                    {/* <button
                                        className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Save Changes
                                    </button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </div>
    )
}

export default Modal